import React from 'react'
import { Link } from 'gatsby'

export default function Navbar() {

    return (
        <nav className="navbar navbar-expand-xxl navbar-dark fixed-top smart-navbar" aria-label="Seventh navbar example">
            <div className="container-fluid">
                <a className="navbar-brand" href="https://www.tnstate.edu" target="_blank" rel="noreferrer">
                    <img src="/tsulogo.svg" alt="Tennesee State University" className="smart-logo"/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExampleXxl" aria-controls="navbarsExampleXxl" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="smart-menu">Menu</span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleXxl">
                    <ul className="navbar-nav me-auto mb-2 mb-xl-0 smart-nav">
                        <li className="nav-item">
                            <Link className="nav-link smart-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/innovation">Tech Innovation Initiatives</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/alumni">Alumni Programs</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/esports">Esports</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/oer">Open Educational Resources</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://ai-tnstatesmartcenter.org/artificial-intelligence--a-i/artificial-intelligence" target="_blank" rel="noreferrer">Artificial Intelligence (AI)</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.tnstate.edu/online/" target="_blank" rel="noreferrer">Global Online</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.tnstate.edu/continuinged/" target="_blank" rel="noreferrer">Continuing Ed</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.hbcuc2.org" target="_blank" rel="noreferrer">HBCU C<sup>2</sup></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}