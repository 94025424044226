import React from 'react'
import Navbar from './navbar'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import "../styles/global.css"

export default function Layout({ children }) {

    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `)

    const { title, description } = data.site.siteMetadata

    return (
        <div className="layout">
        <Helmet>
            <html lang="en" />
            <meta name="description" content={description} />
            <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&family=Raleway:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap" rel="stylesheet" />
            <script src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js" integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D" crossorigin="anonymous" async></script>
            <title>{title}</title>
        </Helmet>
        <Navbar />
            <div className="content">
                { children }
            </div>
        <footer></footer>
        </div>
    )
}